export const validateEmail = (email: string) => {
	const EMAIL_ADDRESS_REGEX = new RegExp(
		'(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])'
	)
	return !(email.match(EMAIL_ADDRESS_REGEX) == null)
}

/* Validates the phone number to fit the following formats:
        (123) 456-7890
        (123)456-7890
        123-456-7890
        123.456.7890
        1234567890
        +31636363634
        075-63546725
*/
export const validatePhone = (phoneNumber: string) => {
	const PHONE_NUMBER_REGEX = new RegExp(/^\+?[0-9-.() ]+$/)
	return phoneNumber.length >= 8 && !(phoneNumber.match(PHONE_NUMBER_REGEX) == null)
}

// Remove anything but digits in a phone number
export const stripPhone = (phoneNumber: string) => {
	const PHONE_NUMBER_REGEX = new RegExp(/[^\d]/g)

	return phoneNumber.replace(PHONE_NUMBER_REGEX, '')
}

const COMMON_DOMAINS = ['.com', '.net', '.org', '.edu', '.gov', '.ru', '.de', '.br', '.uk', '.jp', '.fr', '.it', '.co', '.dev']

export const emailEndsWithCommonDomain = (email: string) => {
	return COMMON_DOMAINS.some((domain) => email.endsWith(domain))
}
