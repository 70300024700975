import { FC } from 'react'
import checkoutStyles from 'components/Checkout2023/Checkout.module.scss'
import cx from 'classnames'

export interface CheckboxProps {
	checked: boolean
	onChange: (newValue: boolean) => void
	text?: string
	id: string
}

export const Checkbox: FC<CheckboxProps> = (props) => (
	<>
		<input
			type={'checkbox'}
			id={`${props.id}-checkbox`}
			className={'vh'}
			checked={props.checked}
			onChange={(e) => props.onChange(e.target.checked)}
		/>
		<label
			htmlFor={`${props.id}-checkbox`}
			className={checkoutStyles.checkbox_container}
		>
			<span className={cx(checkoutStyles.checkbox, { [checkoutStyles.checkbox_selected]: props.checked })}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 11 8"
					fill="none"
					className={checkoutStyles.checkbox_check}
				>
					<path
						d="M3.7 7.99L0 4.19L1 3.09L3.7 5.79L9.2 0.389999L10.2 1.39L3.7 7.99Z"
						fill="white"
					/>
				</svg>
			</span>
			<p>{props.text}</p>
		</label>
	</>
)
